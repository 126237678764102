// Bootstrap environment
// import 'babel-polyfill';
import 'normalize.css';

import './assets/stylesheets/fonts.module.css';

if (!global.Intl) {
  import('intl').then(intl => {
    import('intl/locale-data/jsonp/en.js');
  });
}

import('./main');
